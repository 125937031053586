import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TableResizeMixin extends Vue {
    tableHeight: any = '';

    $refs!: {
        table: Vue
    }

    resizeTable() {
      try {
        const tableRef = this.$refs.table;
        if (this.$vuetify.breakpoint.smAndDown) {
          this.tableHeight = window.innerHeight - tableRef.$el.getBoundingClientRect().top - 56;
        } else {
          this.tableHeight = window.innerHeight - tableRef.$el.getBoundingClientRect().top;
        }
      } catch (e) {
      }
    }

    mounted() {
      this.resizeTable();
    }

    created() {
      this.$nextTick(() => {
        this.$eventBus.$on('resize', this.resizeTable);
      });
    }

    destroyed() {
      this.$eventBus.$off('resize', this.resizeTable);
    }
}
