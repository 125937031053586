var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-sheet",
    {
      ref: "teachersRef",
      staticClass: "teachers",
      attrs: { color: "#ffffff" },
    },
    [
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-sheet",
            { staticClass: "mx-auto", attrs: { elevation: "0" } },
            [
              _vm.$currentUser.isDistrictAdmin || _vm.haveMultipleSchools
                ? _c(
                    "v-slide-group",
                    {
                      staticClass: "py-2",
                      attrs: { "center-active": "" },
                      model: {
                        value: _vm.localSlideGroupModel,
                        callback: function ($$v) {
                          _vm.localSlideGroupModel = $$v
                        },
                        expression: "localSlideGroupModel",
                      },
                    },
                    [
                      _c(
                        "v-slide-item",
                        { key: 0 },
                        [
                          _c("v-autocomplete", {
                            staticClass: "ml-2 pill-mobile-filters",
                            staticStyle: {
                              "max-width": "200px",
                              "font-size": "12px",
                            },
                            attrs: {
                              rounded: "",
                              dense: "",
                              flat: "",
                              solo: "",
                              outlined: "",
                              "hide-details": "auto",
                              items: _vm.schoolItems,
                            },
                            model: {
                              value: _vm.currentSchoolId,
                              callback: function ($$v) {
                                _vm.currentSchoolId = $$v
                              },
                              expression: "currentSchoolId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "rounded-0", attrs: { flat: "" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "border-bottom": "1px solid #e4e4e4",
                        padding: "3px 15px",
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("totalNumberOfAccountsLabel", {
                              accounts: _vm.filteredLocalTeachers.length,
                            })
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c("v-data-table", {
                    ref: "table",
                    attrs: {
                      "footer-props": _vm.footerProps,
                      "multi-sort": false,
                      "show-select": _vm.editMode,
                      loading: _vm.listLoading,
                      headers: _vm.tableHeaders,
                      items: _vm.filteredLocalTeachers,
                      "item-key": "key",
                      height: _vm.tableHeight,
                      search: _vm.searchText,
                      "fixed-header": "",
                      "hide-default-footer": "",
                      "disable-pagination": "",
                      "mobile-breakpoint": _vm.mobileBreakpoint,
                    },
                    on: { "click:row": _vm.editTeacher },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function ({ items, isSelected, select }) {
                          return [
                            _c(
                              "tbody",
                              {
                                ref: "listContainer",
                                staticClass: "list-container",
                              },
                              _vm._l(items, function (item, index) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    class: _vm.mobileTableClass,
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { tabindex: "0", role: "row" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.editTeacher(item)
                                      },
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.editTeacher(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm.editMode
                                      ? _c(
                                          "td",
                                          {
                                            class: _vm.mobileRowClass,
                                            on: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                select(item, !isSelected(item))
                                              },
                                            },
                                          },
                                          [
                                            _vm.isMobileMode
                                              ? _c("div", {
                                                  class: _vm.mobileHeaderClass,
                                                })
                                              : _vm._e(),
                                            _c("v-checkbox", {
                                              class: _vm.mobileCellClass,
                                              staticStyle: {
                                                margin: "0px",
                                                padding: "0px",
                                              },
                                              attrs: {
                                                "input-value": isSelected(item),
                                                color: !_vm.$vuetify.theme.dark
                                                  ? "#7a7a7a"
                                                  : "#ffffff",
                                                "hide-details": "",
                                                "aria-label":
                                                  _vm.getDisplayName(item),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  select(
                                                    item,
                                                    !isSelected(item)
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "td",
                                      { class: _vm.mobileRowClass },
                                      [
                                        _vm.isMobileMode
                                          ? _c(
                                              "div",
                                              { class: _vm.mobileHeaderClass },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.tableHeaders[0].text
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-avatar",
                                          {
                                            class: _vm.mobileCellClass,
                                            attrs: {
                                              color: "primary",
                                              size: "2.5rem",
                                            },
                                          },
                                          [
                                            _vm.hasText(item.photoUrl)
                                              ? _c("v-img", {
                                                  attrs: { src: item.photoUrl },
                                                })
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass: "white--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getInitials(item)
                                                      )
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.tableHeaders[1].text)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [
                                          item.userType == "D"
                                            ? _c(
                                                "v-chip",
                                                {
                                                  staticClass:
                                                    "red--text text-lighten-3",
                                                  style: {
                                                    cursor: "pointer",
                                                    "font-weight":
                                                      _vm.chipFontWeight,
                                                  },
                                                  attrs: {
                                                    label: "",
                                                    color: "#fee9e7",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.typeName))]
                                              )
                                            : item.userType == "A"
                                            ? _c(
                                                "v-chip",
                                                {
                                                  staticClass:
                                                    "blue--text text-lighten-2",
                                                  style: {
                                                    cursor: "pointer",
                                                    "font-weight":
                                                      _vm.chipFontWeight,
                                                  },
                                                  attrs: {
                                                    label: "",
                                                    color: "#e5f3fe",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.typeName))]
                                              )
                                            : item.userType == "B"
                                            ? _c(
                                                "v-chip",
                                                {
                                                  staticClass:
                                                    "teal--text text-lighten-3",
                                                  style: {
                                                    cursor: "pointer",
                                                    "font-weight":
                                                      _vm.chipFontWeight,
                                                  },
                                                  attrs: {
                                                    label: "",
                                                    color: "#e1f3f1",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.typeName))]
                                              )
                                            : _c(
                                                "v-chip",
                                                {
                                                  staticClass:
                                                    "indigo--text text-lighten-4",
                                                  style: {
                                                    cursor: "pointer",
                                                    "font-weight":
                                                      _vm.chipFontWeight,
                                                  },
                                                  attrs: {
                                                    label: "",
                                                    color: "#e8ebf6",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.typeName))]
                                              ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.tableHeaders[2].text)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [_vm._v(_vm._s(item.emailAddress))]
                                      ),
                                    ]),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.tableHeaders[3].text)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.editFirstName
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileCellClass },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  autofocus: "",
                                                  dense: "",
                                                  "hide-details": "",
                                                  "append-icon": "fa fa-check",
                                                  value: item.firstName,
                                                  disabled: _vm.listLoading,
                                                },
                                                on: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.saveFirstName(
                                                      item
                                                    )
                                                  },
                                                  input: function ($event) {
                                                    return _vm.updateFirstName(
                                                      item,
                                                      $event
                                                    )
                                                  },
                                                  "click:append": function (
                                                    $event
                                                  ) {
                                                    return _vm.saveFirstName(
                                                      item
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.disableEditable(
                                                      false,
                                                      true
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              class: _vm.mobileCellClass,
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.toggleFirstNameEditable(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.firstName)),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ml-2 hover-edit-icon",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { "x-small": "" },
                                                    },
                                                    [_vm._v("fal fa-pencil")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                    ]),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.tableHeaders[4].text)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.editLastName
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileCellClass },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  autofocus: "",
                                                  dense: "",
                                                  "hide-details": "",
                                                  "append-icon": "fa fa-check",
                                                  value: item.lastName,
                                                  disabled: _vm.listLoading,
                                                },
                                                on: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.saveLastName(
                                                      item
                                                    )
                                                  },
                                                  input: function ($event) {
                                                    return _vm.updateLastName(
                                                      item,
                                                      $event
                                                    )
                                                  },
                                                  "click:append": function (
                                                    $event
                                                  ) {
                                                    return _vm.saveLastName(
                                                      item
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.disableEditable(
                                                      true,
                                                      false
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              class: _vm.mobileCellClass,
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.toggleLastNameEditable(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.lastName)),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ml-2 hover-edit-icon",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { "x-small": "" },
                                                    },
                                                    [_vm._v("fal fa-pencil")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                    ]),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.tableHeaders[5].text)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        { class: _vm.mobileCellClass },
                                        [
                                          _vm._v(
                                            _vm._s(item.formattedLastLogon)
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm.haveRosteredItems
                                      ? _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c("div", {
                                                  class: _vm.mobileHeaderClass,
                                                })
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { class: _vm.mobileCellClass },
                                              [
                                                _c("rostered-item-indicator", {
                                                  key:
                                                    item.teacherId +
                                                    item.isItemRostered,
                                                  attrs: {
                                                    itemType: "teacher",
                                                    item: item,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("td", { class: _vm.mobileRowClass }),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.tableHeaders[6].text)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "mx-3 hover-button-primary",
                                              attrs: {
                                                text: "",
                                                small: "",
                                                color:
                                                  _vm.contentBreakpoint === "xs"
                                                    ? "primary"
                                                    : "transparent",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.editTeacher(item)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("editLabel")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "mx-3 hover-button-error",
                                              attrs: {
                                                text: "",
                                                small: "",
                                                color:
                                                  _vm.contentBreakpoint === "xs"
                                                    ? "error"
                                                    : "transparent",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.deleteTeacher(item)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("removeLabel")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedRows,
                      callback: function ($$v) {
                        _vm.selectedRows = $$v
                      },
                      expression: "selectedRows",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" }),
      _c(
        "pb-base-modal",
        {
          attrs: { maxWidth: 650, expandable: false, withActions: false },
          on: { apply: _vm.inviteTeachers },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(_vm._s(_vm.$t("inviteTeachersLabel")))]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.localShowInviteTeachersDialog,
            callback: function ($$v) {
              _vm.localShowInviteTeachersDialog = $$v
            },
            expression: "localShowInviteTeachersDialog",
          },
        },
        [
          _c(
            "v-form",
            { ref: "inviteTeachersForm" },
            [
              _c(
                "v-container",
                { staticClass: "pb-5" },
                [
                  _c(
                    "v-row",
                    [
                      _vm.userType === "D"
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.schoolItems,
                                  outlined: "",
                                  "hide-details": "auto",
                                  label: _vm.$t("schoolLabel"),
                                },
                                model: {
                                  value: _vm.localSchoolId,
                                  callback: function ($$v) {
                                    _vm.localSchoolId = $$v
                                  },
                                  expression: "localSchoolId",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("teacher-tags-input", {
                            model: {
                              value: _vm.localTeachersToInvite,
                              callback: function ($$v) {
                                _vm.localTeachersToInvite = $$v
                              },
                              expression: "localTeachersToInvite",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "pb-base-modal",
        {
          attrs: {
            maxWidth: 650,
            expandable: false,
            withActions: false,
            withApply: false,
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(_vm._s(_vm.$t("generateCodesLabel")))]
              },
              proxy: true,
            },
            {
              key: "bottom-actions",
              fn: function () {
                return [
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2 mb-2",
                          attrs: {
                            small: _vm.$vuetify.breakpoint.smAndDown,
                            color: "error",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.emailJoinUrlToMe.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("emailToLabel")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2 mb-2",
                          attrs: {
                            small: _vm.$vuetify.breakpoint.smAndDown,
                            color: "error",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.copyJoinUrlToClipboard.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("copyClipboardLabel")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-1 mb-2",
                          attrs: {
                            small: _vm.$vuetify.breakpoint.smAndDown,
                            color: "error",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.downloadJoinUrl.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("downloadLink2Label")))]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.localShowJoinCodesDialog,
            callback: function ($$v) {
              _vm.localShowJoinCodesDialog = $$v
            },
            expression: "localShowJoinCodesDialog",
          },
        },
        [
          _c(
            "v-form",
            { ref: "generateJoinCodesForm" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _vm.userType === "D"
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.schoolItems,
                                  outlined: "",
                                  "hide-details": "auto",
                                  label: _vm.$t("schoolLabel"),
                                },
                                model: {
                                  value: _vm.localSchoolId,
                                  callback: function ($$v) {
                                    _vm.localSchoolId = $$v
                                  },
                                  expression: "localSchoolId",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { attrs: { col: "12" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              outlined: "",
                              "hide-details": "auto",
                              "no-resize": "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.localJoinURL,
                              callback: function ($$v) {
                                _vm.localJoinURL = $$v
                              },
                              expression: "localJoinURL",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("copy-school-year", {
        model: {
          value: _vm.showCopySchoolYear,
          callback: function ($$v) {
            _vm.showCopySchoolYear = $$v
          },
          expression: "showCopySchoolYear",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }