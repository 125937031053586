











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CommonUtils from '@/utils/common-utils';
import SchoolYearServices from '@/services/school-year-services';
import { namespace } from 'vuex-class';

const teachers = namespace('teachers');

@Component
export default class CopySchoolYear extends Vue {
    @Prop({ type: Boolean })
    value!: boolean;

    get localValue() {
      return this.value;
    }

    set localValue(val: boolean) {
      this.$emit('input', val);
    }

    localTeacherId = 1;
    localSelectedYearId = 0;

    @teachers.Getter('getTeachersOnly')
    teachersOnly!: Array<any>;

    @teachers.Getter
    getTeacherSchoolYears!: (teacherId: number) => any;

    @teachers.Action
    loadTeachers!: () => Promise<any>;

    $refs!: {
      copySchoolYearLabelForm: Vue & {
        validate: () => boolean;
        resetValidation: () => void;
      };
    };

    get teacherItems() {
      return [
        {
          value: 1,
          text: this.$t('allTeachersLabel')
        }
      ].concat(
        this.teachersOnly.map(t => {
          return { value: t.teacherId, text: t.emailAddress };
        })
      );
    }

    get schoolYears(): Array<any> {
      return this.getTeacherSchoolYears(this.teacherId);
    }

    get schoolYearItems() {
      return this.schoolYears.map(s => {
        return { value: s.yearId, text: s.yearName };
      });
    }

    get teacher() {
      return this.$store.state.teachers.currentTeacher || {};
    }

    get teacherId(): number {
      return this.teacher.teacherId;
    }

    get emailAddress(): string {
      return this.teacher.emailAddress;
    }

    get emailAddressToCopy() {
      const item = this.teacherItems.find(t => this.localTeacherId > 1 && t.value === this.localTeacherId);
      return item ? item.text : undefined;
    }

    get schoolIdToCopy() {
      const primary = this.primarySchool || {};
      return this.localTeacherId === 1 ? primary.schoolId : undefined;
    }

    get primarySchool() {
      return this.teacher.primarySchool;
    }

    get haveTeachers() {
      return this.$store.state.teachers.haveTeachers;
    }

    set haveTeachers(value: boolean) {
      this.$store.commit('teachers/setHaveTeachers', value);
    }

    @Watch('value')
    init() {
      this.localSelectedYearId = this.schoolYearItems[0] ? this.schoolYearItems[0].value : 0;
    }

    doCopySchoolYear() {
      if (this.$refs.copySchoolYearLabelForm.validate()) {
        CommonUtils.showLoading();
        SchoolYearServices.copyTerm({
          adminCopy: 'Y',
          view: 'copyYear',
          yearId: this.localSelectedYearId,
          copyToTeacher: this.emailAddressToCopy,
          copyToSchoolId: this.schoolIdToCopy
        }).then(() => {
          this.haveTeachers = false;
          return this.loadTeachers();
        }).finally(() => {
          this.localValue = false;
          CommonUtils.hideLoading();
        });
      }
    }
}
