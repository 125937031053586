import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PageLifeCycleMixin extends Vue {
  created() {
    this.$nextTick(() => {
      this.$store.commit('setCurrentPageInstance', this);
      this.$store.commit('setCurrentPage', CommonUtils.getPage(this.$route));
    })
  }

  beforeDestroy() {
    this.$store.commit('setCurrentPageInstance', null);
    this.$store.commit('setCurrentPage', '');
  }
}
