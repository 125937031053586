import { render, staticRenderFns } from "./Teachers.vue?vue&type=template&id=e8fb6d14&scoped=true"
import script from "./Teachers.vue?vue&type=script&lang=ts"
export * from "./Teachers.vue?vue&type=script&lang=ts"
import style0 from "./Teachers.vue?vue&type=style&index=0&id=e8fb6d14&lang=scss"
import style1 from "./Teachers.vue?vue&type=style&index=1&id=e8fb6d14&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8fb6d14",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAutocomplete,VAvatar,VBtn,VCard,VCardActions,VCheckbox,VChip,VCol,VContainer,VDataTable,VForm,VIcon,VImg,VRow,VSelect,VSheet,VSlideGroup,VSlideItem,VSpacer,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e8fb6d14')) {
      api.createRecord('e8fb6d14', component.options)
    } else {
      api.reload('e8fb6d14', component.options)
    }
    module.hot.accept("./Teachers.vue?vue&type=template&id=e8fb6d14&scoped=true", function () {
      api.rerender('e8fb6d14', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/Teachers.vue"
export default component.exports