var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-modal",
    {
      attrs: { maxWidth: 650, expandable: false, withActions: false },
      on: { apply: _vm.doCopySchoolYear },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(_vm._s(_vm.$t("copySchoolYearLabel")))]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c(
        "v-form",
        { ref: "copySchoolYearLabelForm" },
        [
          _c(
            "v-container",
            { staticClass: "pb-5" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        attrs: {
                          "persistent-hint": "",
                          outlined: "",
                          "hide-details": "auto",
                          label: _vm.$t("copyFromLabel"),
                          hint: _vm.$t("pickSchoolYearToCopyHint", {
                            emailAddress: _vm.emailAddress
                          }),
                          items: _vm.schoolYearItems,
                          rules: [
                            function(v) {
                              return !!v || _vm.$t("requiredMsg2")
                            }
                          ]
                        },
                        model: {
                          value: _vm.localSelectedYearId,
                          callback: function($$v) {
                            _vm.localSelectedYearId = $$v
                          },
                          expression: "localSelectedYearId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        attrs: {
                          "persistent-hint": "",
                          outlined: "",
                          "hide-details": "auto",
                          items: _vm.teacherItems,
                          label: _vm.$t("copyToLabel"),
                          hint: _vm.$t("pickTeacherToCopyIntoHint"),
                          rules: [
                            function(v) {
                              return !!v || _vm.$t("requiredMsg2")
                            }
                          ]
                        },
                        model: {
                          value: _vm.localTeacherId,
                          callback: function($$v) {
                            _vm.localTeacherId = $$v
                          },
                          expression: "localTeacherId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }